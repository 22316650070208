<template>
  <div class="date">
    <el-calendar :first-day-of-week="7">
    </el-calendar>
  </div>
</template>

<script>
export default {
  name: 'date'
}
</script>

<style lang="less">
.date {
  .el-calendar-day {
    height: 40px;
    line-height: 40px;
    padding: 0;
  }

  .el-calendar__button-group {
    display: none;
  }
}
</style>
