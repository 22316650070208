import axios from 'axios'

export const ajax = axios.create({
  // headers: {
  //   'Content-Type': 'application/json'
  // },
  // withCredentials: true
})
ajax.interceptors.request.use(function (config) {
  return config
}, function (error) {
  // 对请求错误做些什么
  // window.app.$toast.clear()
  window.app.$message.error('提交参数有误，请确认相关参数！')
  return Promise.reject(error)
})

ajax.interceptors.response.use(function (response) {
  return response
}, function (error) {
  // 对响应错误做点什么
  window.app.$message.error('服务器正忙，请稍候重试！')
  return Promise.reject(error)
})
