import CryptoJS from 'crypto-js';

export const appid = 'nCj58tOSaYxGadMpRM';
export const secret = 'QEdmuUZITjB6mPqFHa77OVVbxEhlhvwwlGOcLtSVCrXTCC8abHKCBBHJY8kkaYuE';
export const url = 'https://api.verjs.cn/index/index.html';
// export const url = 'https://admin-verjs.verjs.cn/index/index.html';
// const key = CryptoJS.enc.Utf8.parse(CryptoJS.MD5(window.app.$store.state.aes_key));
export const AES = {
  decode(data) {
    let key = CryptoJS.enc.Utf8.parse(CryptoJS.MD5(window.app.$store.state.aes_key).toString())
    data = CryptoJS.AES.decrypt(data,key , {
      iv: CryptoJS.enc.Utf8.parse(window.app.$store.state.aes_iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    data = CryptoJS.enc.Utf8.stringify(data).toString();
    if (/^(\{|\[).+(\}|\])$/.test(data)) {
      data = JSON.parse(data);
    }
    return data;
  }
};
