export const RECOMMEND = [
  {
    name: 'Phpstorm',
    image: '/static/images/phpstrom.jpg',
    jingle: 'PhpStorm是JetBrains公司开发的一款商业的PHP集成开发工具，旨在提高用户效率，可深刻理解用户的编码，提供智能代码补全，快速导航以及即时错误检查。',
    link: 'https://www.jetbrains.com/phpstorm/download/',
    tag: 'PHP IDEA Phpstorm JetBrains',
    btn:[{
      name: '下载',
      link: 'https://www.jetbrains.com/phpstorm/download/',
      // link: {
      //   name: 'home',
      //   query: {id:1,_times:2222}
      // },
      target: '_blank',
      icon: 'el-icon-download'
    }]
  },
  {
    name: 'Webstorm',
    image: '/static/images/WebStrom.png',
    jingle: 'WebStorm是JetBrains公司旗下一款JavaScript开发工具。已经被广大中国JS开发者誉为“Web前端开发神器”、“最强大的HTML5编辑器”、“最智能的JavaScriptIDE”等。与IntelliJ IDEA同源，继承了IntelliJ IDEA强大的JS部分的功能。',
    link: 'https://www.jetbrains.com/webstorm/download/',
    tag: 'HTML WEB IDEA Webstorm JetBrains',
    btn:[{
      name: '下载',
      link: 'https://www.jetbrains.com/webstorm/download/',
      target: '_blank',
      icon: 'el-icon-download'
    }]
  },
  {
    name: 'HBuilder',
    image: '/static/images/HBuilder.jpg',
    jingle: 'HBuilder是DCloud（数字天堂）推出的一款支持HTML5的Web开发IDE。HBuilder的编写用到了Java、C、Web和Ruby。HBuilder本身主体是由Java编写。',
    link: 'https://www.dcloud.io/hbuilderx.html',
    tag: 'HTML WEB IDEA HBuilder DCloud',
    btn:[{
      name: '下载',
      link: 'https://www.dcloud.io/hbuilderx.html',
      target: '_blank',
      icon: 'el-icon-download'
    }]
  },
  {
    name: 'VsCode',
    image: '/static/images/vscode.jpg',
    jingle: 'Visual Studio Code（简称“VS Code”）是Microsoft在2015年4月30日Build开发者大会上正式宣布一个运行于 Mac OS X、Windows和 Linux 之上的，针对于编写现代Web和云应用的跨平台源代码编辑器，可在桌面上运行，并且可用于Windows，macOS和Linux。它具有对JavaScript，TypeScript和Node.js的内置支持，并具有丰富的其他语言（例如C++，C＃，Java，Python，PHP，Go）和运行时（例如.NET和Unity）扩展的生态系统。',
    link: 'https://vscode.en.softonic.com/',
    tag: 'HTML WEB IDEA VsCode Microsoft',
    btn:[{
      name: '下载',
      link: 'https://vscode.en.softonic.com/',
      target: '_blank',
      icon: 'el-icon-download'
    }]
  },
  {
    name: 'PyCharm',
    image: '/static/images/pycharm.jpg',
    jingle: 'PyCharm是一种Python IDE（Integrated Development Environment，集成开发环境），带有一整套可以帮助用户在使用Python语言开发时提高其效率的工具，比如调试、语法高亮、项目管理、代码跳转、智能提示、自动完成、单元测试、版本控制。此外，该IDE提供了一些高级功能，以用于支持Django框架下的专业Web开发。',
    link: 'https://www.jetbrains.com/pycharm/download/',
    tag: 'Python IDEA PyCharm jetbrains',
    btn:[{
      name: '下载',
      link: 'https://www.jetbrains.com/pycharm/download/',
      target: '_blank',
      icon: 'el-icon-download'
    }]
  }
];

export const HEADER_MENU = [
  {
    name: 'home',
    title: '首页'
  },
  {
    name: 'recommend',
    title: '开发工具'
  },
  {
    name: 'plug',
    title: '插件库'
  },
  {
    name: 'notice',
    title: '笔记'
  },
];
