import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import VueWechatTitle from 'vue-wechat-title';
import router from './router';
import store from './store';

Vue.use(ElementUI);
Vue.use(VueWechatTitle);
Vue.config.productionTip = false;

// router.beforeEach((to, from, next) => {
//   if (to.meta.title) {
//     document.title = to.meta.title
//   }
//   next()
// })
window.app = new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
