<template>
  <div class="nav">
    <div class="contion">
      <div class="titles" @click="$router.replace({name:'home'})">{{ $store.state.site_info.site_name }}</div>
      <el-menu :default-active="activeIndex" class="nav-items" mode="horizontal" @select="go_pages">
        <el-menu-item :index="menu.name" v-for="(menu,inx) in menu_list" :key="inx">{{
            menu.title
          }}
        </el-menu-item>
        <!--        <el-menu-item index="plug">插件库</el-menu-item>-->
        <!--        <el-menu-item index="note">笔记记录</el-menu-item>-->
      </el-menu>
    </div>
  </div>
</template>

<script>
import { HEADER_MENU } from '@/utils/constant';

export default {
  name: 'header_nav',
  props: ['actives'],
  data() {
    return {
      menu_list: HEADER_MENU
    };
  },
  computed: {
    activeIndex() {
      return this.actives;
    }
  },
  methods: {
    go_pages(index) {
      this.$router.push({
        name: index
      });
    }
  }
};
</script>

<style lang="less">
.nav {
  height: 60px;
  background: #1e9fff;

  .contion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;

    .titles {
      color: #fff;
      font-weight: 600;
    }

    .nav-items {
      background: #1e9fff;
      color: #fff;

      li {
        color: #fff;
      }

      li.is-active {
        font-weight: 600;
        border-bottom-color: #fff;
      }

      li:hover, li:active {
        background: rgba(255, 255, 255, .45);
        color: #fff;
      }
    }
  }

  @media screen and (max-width: 780px) {
    .nav-items {
      display: none;
    }
  }
}
</style>
