import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    refresh_token: '',
    site_info: {},
    last_time: 0,
    aes_iv: '',
    aes_key: '',
    link_info: {},
    access_token: ''
  },
  getters: {},
  mutations: {
    get_token(state, playload) {
      this.state.refresh_token = playload.refresh_token;
      this.state.access_token = playload.access_token;
      this.state.last_time = Date.parse(playload.last_time);
      this.state.aes_iv = playload.aes_iv;
      this.state.aes_key = playload.aes_key;
      this.state.access_token = playload.access_token;
    },
    site_items(state, playload) {
      this.state.site_info = playload;
    },
    friend(state, playload) {
      this.state.link_info = playload;
    }
  },
  actions: {},
  modules: {},
});
