<template>
  <div class="index-list-info">
    <el-card class="index-box-card">
      <div slot="header" class="index-tabs">
        <div>{{ title }}</div>
        <div class="more" v-if="more" @click="more_render">more</div>
      </div>
      <ul class="index-items-list">
        <li v-for="(items,inx) in items" class="index-items-list-info" :inx="inx">
          <div class="titles" data-inx="inx" :data-info="items" :ref="'list-'+inx"
               @click="get_router(inx)">{{ items.name }}
          </div>
          <div class="author">
            <span><i class="el-icon-user"></i> {{ items.author }}</span>
            <span><i class="el-icon-alarm-clock"></i> {{ items.timer }}</span>
          </div>
        </li>
      </ul>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'index-list',
  props: ['title', 'more', 'more_href', 'items', 'length'],
  methods: {
    more_render() {
      this.$router.push(this.more_href);
    },
    get_router(inx) {
      // console.log(this.$refs['list-'+inx]);
      let its = this.items[inx];
      // console.log(its);
      this.$router.push({
        name: 'notice_details',
        params: {
          id: its.id
        }
      });
    }
  }
};
</script>

<style lang="less">
.index-list-info {
  .more {
    color: #1e9fff;
    font-size: 14px;
    cursor: pointer;
  }

  .index-tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .index-items-list {
    margin: 0 !important;
    padding: 0 !important;

    .index-items-list-info {
      display: flex;
      justify-content: space-between;
      margin: 0 !important;
      padding: 5px 0 !important;
      align-items: center;
      line-height: 25px;
      font-size: 12px;
      border-bottom: 1px dashed #D9D9D9;

      .titles {
        cursor: pointer;
        max-width: 60%;
        margin-left: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover {
          color: #00a0e9;
        }
      }

      .author {
        span + span {
          padding-left: 5px;
        }
      }
    }
  }

  .index-box-card{
    margin-bottom: 15px;
  }
}
</style>
