<template>
  <div class="index-tabs-info">
    <el-card class="index-box-card">
      <div slot="header" class="index-tabs">
        <div>{{ title }}</div>
        <div class="more" v-if="more" @click="more_render">more</div>
      </div>
      <div class="index-items-images">
        <div v-for="(recommend,index) in items" :key="index"
             class="index-items-images-list" v-if="index < length_info" @click="get_loction(index)">
          <div class="plug-logo">
            <el-image :src="recommend.image" fit="cover" width="100%">
              <el-image slot="error" src="/static/images/error.png"></el-image>
            </el-image>
            <div class="recommend-title">{{ recommend.name }}</div>
          </div>
          <div class="recommend-content">{{ recommend.jingle }}</div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'index-tabs',
  props: ['title', 'more', 'more_href', 'items', 'length'],
  methods: {
    more_render() {
      this.$router.push(this.more_href);
    },
    get_loction(inx) {
      let link = (this.items[inx].link);
      if (typeof link == 'object') {
        return this.$router.push(link);
      } else {
        let { href } = this.$router.resolve({
          name: 'download',
          query: {
            url: link
          }
        });
        return window.open(href, '_blank', console.log(href));
      }
    }
  },
  computed: {
    length_info() {
      return this.length || this.items.length;
    }
  }
};
</script>

<style lang="less">
.index-tabs-info {
  .index-box-card {
    margin-bottom: 15px;

    .el-card__header {
      padding: 10px 20px;
    }

    .el-card__body {
      padding: 0 !important;
    }

    .index-tabs {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .more {
        color: #1e9fff;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .index-items-images {
      display: flex;
      overflow-x: auto;
      padding: 10px 20px;
      box-sizing: border-box;

      .index-items-images-list {
        width: 25%;
        flex: 0 0 25%;
        text-align: left;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 5px;
        align-items: center;
        cursor: pointer;

        .plug-logo {
          width: 100%;
          position: relative;
          box-sizing: border-box;
          overflow: hidden;
          border: 1px solid #D9D9D9;
          border-bottom: 0;
          border-radius: 5px 5px 0 0;

          .recommend-title {
            position: absolute;
            background: rgba(185, 185, 185, .8);
            left: 0;
            right: 0;
            bottom: 0;
            color: #fff;
            font-weight: bold;
            font-size: 18px;
            padding-left: 5px;
            box-sizing: border-box;
            line-height: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .recommend-content {
          line-height: 25px;
          font-size: 12px;
          padding: 5px 10px;
          box-sizing: border-box;
          overflow: hidden;
          word-wrap: break-word;
          display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示
          -webkit-box-orient: vertical; // 从上到下垂直排列子元素
          -webkit-line-clamp: 3; // 表示显示的行数（需要结合上面两个属性）
          border: 1px solid #d9d9d9;
          border-top: 0;
          border-radius: 0 0 5px 5px;
        }
      }

    }
  }
}
</style>
