<template>
  <div id="app">
    <HeaderNav :actives="page_actives" v-if="!download_page"/>
    <div class="contion" v-if="!download_page">
      <el-container class="page-content">
        <el-main class="main">
          <router-view v-wechat-title="$route.meta.title"/>
        </el-main>
        <el-aside class="right">
          <DateIems/>
        </el-aside>
      </el-container>
    </div>
    <el-backtop v-if="!download_page"></el-backtop>
    <router-view v-wechat-title="$route.meta.title" v-else-if="download_page"/>
    <div class="footer" v-if="!download_page">
      <div class="friend_link">
        <el-button type="text" v-for="(friend,inx) in $store.state.link_info" :ref="'friend_'+inx"
                   :data-info="friend" @click="friend_go(inx)">{{ friend.name }}
        </el-button>
      </div>
      <div class="copyright" v-html="'&copy; '+$store.state.site_info.site_copyright"></div>
      <div class="tell_me">
        <span>E-mail：{{ $store.state.site_info.site_email }}</span>
        <span>QQ：{{ $store.state.site_info.site_qq }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { get_web_site, get_friend_link } from '@/utils/common';
import HeaderNav from '@/components/common/header-nav';
import DateIems from '@/components/common/date';
import IndexTabs from '@/components/home/index-tabs';

export default {
  components: {
    HeaderNav,
    DateIems,
    IndexTabs
  },
  computed: {
    page_actives() {
      return this.$route.name;
    },
    download_page() {
      return this.$route.name == 'download';
    }
  },
  beforeCreate() {
    get_web_site(this);
    get_friend_link(this);
  },
  methods: {
    friend_go(inx) {
      let ref = this.$refs['friend_' + inx][0];
      let info = ref.$attrs['data-info'];
      let go = {
        name: 'download',
        query: {
          url: info.link
        }
      };
      if (info.open_type == '_blank') {
        let { href } = this.$router.resolve(go);

        window.open(href, '_blank', console.log(href));
      } else {
        this.$router.push(go);
      }
    }
  }
};
</script>
<style lang="less">
body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  .el-card__header, .el-card__body {
    padding: 10px 20px;
  }

  .contion {
    width: 93.7vw;
    margin: auto;
  }

  .page-content {
    margin-top: 10px;

    .main {
      padding: 0;
      text-align: left;
      margin-right: 15px;
    }
  }

  ul, ol, li {
    list-style: none;
  }

  .loactions {
    margin-bottom: 10px;
  }

  .footer {
    margin-top: 15px;
    height: 120px;
    background: #1e9fff;
    text-align: center;
    padding: 15px;
    color: #fff;
    line-height: 25px;

    .friend_link {
      button {
        color: #fff !important;
      }
    }

    .copyright {
      font-size: 14px;

      a {
        color: #fff;
        text-decoration: none;
      }
    }

    .tell_me{
      span{
        padding-right: 10px;
      }
    }
  }
}

@media screen and (max-width: 780px) {
  .contion {
    width: 100vw;

    .right {
      display: none;
    }

    .main {
      margin-right: 0;
      width: 100%;
    }
  }
}
</style>
