import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: '小白笔记',
    },
  },
  {
    path: '/recommend',
    name: 'recommend',
    component: () => import(/* webpackChunkName: "about" */ '../views/recommend.vue'),
    meta: {
      title: '开发工具'
    }
  },
  {
    path: '/download',
    name: 'download',
    component: () => import(/* webpackChunkName: "about" */ '../views/download.vue'),
    meta: {
      title: '页面跳转'
    }
  },
  {
    path: '/plug/list',
    name: 'plug',
    component: () => import(/* webpackChunkName: "about" */ '../views/plug_list.vue'),
    meta: {
      title: '插件库'
    }
  },
  {
    path: '/plug/details/:id',
    name: 'plug_details',
    component: () => import(/* webpackChunkName: "about" */ '../views/plug_details.vue'),
    meta: {
      title: '插件介绍'
    }
  },
  {
    path: '/notice/list',
    name: 'notice',
    component: () => import(/* webpackChunkName: "about" */ '../views/notice.vue'),
    meta: {
      title: '笔记'
    }
  },
  {
    path: '/notice/details/:id',
    name: 'notice_details',
    component: () => import(/* webpackChunkName: "about" */ '../views/notice_details.vue'),
    meta: {
      title: '笔记详情'
    }
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
