<template>
  <div class="page-home">
    <IndexTabs title="工具推荐" :items="recommend_list" :more="true" :more_href="{name:'recommend'}" :length="4"/>
    <IndexList title="笔记" :items="article_reco" :more="true" :more_href="{name:'notice'}"/>
    <IndexTabs title="插件推荐" :items="plug_reco" :more="true" :more_href="{name:'plug'}"/>
  </div>
</template>

<script>
// @ is an alias to /src
import IndexTabs from '@/components/home/index-tabs';
import { RECOMMEND } from '@/utils/constant';
import { get_plug_reco,get_article_reco } from '@/utils/common';
import IndexList from '@/components/home/index-list';

export default {
  name: 'HomeView',
  components: {
    IndexList,
    IndexTabs
  },
  data() {
    return {
      recommend_list: RECOMMEND,
      plug_reco: [],
      article_reco: []
    };
  },
  mounted() {
    get_plug_reco(this);
    get_article_reco(this);
  },
  methods: {}
};
</script>

<style lang="less">

</style>
