import { ajax } from '@/utils/ajax';
import { AES, appid, secret, url } from '@/utils/apis';

/**
 * 获取token
 */
async function get_token(self) {
  let last_time = self.$store.state.last_time;
  if (last_time < 1 || Date.parse(new Date()) > last_time) {
    await ajax.post(url, {
      method: 'access/token',
      result: {
        secret: secret,
        appid: appid
      }
    })
      .then(({ data }) => {
        if (data.message.errcode == 0) {
          self.$store.commit('get_token', data.result);
        }
      });
  }
}

/**
 * 获取网站信息
 * @param self
 * @returns {Promise<void>}
 */
export async function get_web_site(self) {
  await get_token(self);
  ajax.post(url, {
    method: 'web_site/info',
    result: {
      access_token: self.$store.state.access_token,
      appid: appid
    }
  })
    .then(({ data }) => {
      if (data.message.errcode == 0) {
        //解密参数
        let dd = AES.decode(data.result);
        self.$store.commit('site_items', dd);
      }
    });
}

export async function get_friend_link(self) {
  await get_token(self);
  ajax.post(url, {
    method: 'web_site/friend_list',
    result: {
      access_token: self.$store.state.access_token,
      appid: appid,
      page: 1,
      type: 'default'
    }
  })
    .then(({ data }) => {
      if (data.message.errcode == 0) {
        //解密参数
        let dd = AES.decode(data.result);
        // console.log(dd);
        self.$store.commit('friend', dd.data);
      }
    });
}

/**
 * 插件推荐
 * @param self
 * @returns {Promise<void>}
 */
export async function get_plug_reco(self) {
  await get_token(self);
  ajax.post(url, {
    method: 'plug/reco',
    result: {
      access_token: self.$store.state.access_token,
      appid: appid
    }
  })
    .then(({ data }) => {
      if (data.message.errcode == 0) {
        //解密参数
        let dd = AES.decode(data.result);
        self.plug_reco = dd;
      }
    });
}

/**
 * 插件列表
 * @param self
 * @returns {Promise<void>}
 */
export async function get_plug_list(self) {
  await get_token(self);
  ajax.post(url, {
    method: 'plug/list',
    result: {
      access_token: self.$store.state.access_token,
      appid: appid,
      page: self.page
    }
  })
    .then(({ data }) => {
      if (data.message.errcode == 0) {
        //解密参数
        let dd = AES.decode(data.result);
        console.log(dd);
        self.plug_list = dd.data;
      }
    });
}

/**
 * 插件详情
 * @param self
 * @returns {Promise<void>}
 */
export async function get_plug_details(self) {
  await get_token(self);
  ajax.post(url, {
    method: 'plug/details',
    result: {
      access_token: self.$store.state.access_token,
      appid: appid,
      id: self.id
    }
  })
    .then(({ data }) => {
      if (data.message.errcode == 0) {
        //解密参数
        let details = AES.decode(data.result);
        if (details.items) {
          details.items = details.items.replace(/rn/g, '<br>');
        }

        self.details = details;
      }
    });
}

/**
 * 首页推荐笔记
 * @param self
 * @returns {Promise<void>}
 */
export async function get_article_reco(self) {
  await get_token(self);
  ajax.post(url, {
    method: 'article/reco',
    result: {
      access_token: self.$store.state.access_token,
      appid: appid
    }
  })
    .then(({ data }) => {
      if (data.message.errcode == 0) {
        //解密参数
        let details = AES.decode(data.result);
        self.article_reco = details;
      }
    });
}

/**
 * 文章列表
 * @param self
 * @returns {Promise<void>}
 */
export async function get_article_list(self) {
  await get_token(self);
  ajax.post(url, {
    method: 'article/list',
    result: {
      access_token: self.$store.state.access_token,
      appid: appid,
      page: self.page
    }
  })
    .then(({ data }) => {
      if (data.message.errcode == 0) {
        //解密参数
        let details = AES.decode(data.result);
        self.notice_list = details.data;
      }
    });
}

export async function get_article_details(self) {
  await get_token(self);
  ajax.post(url, {
    method: 'article/details',
    result: {
      access_token: self.$store.state.access_token,
      appid: appid,
      id: self.id
    }
  })
    .then(({ data }) => {
      if (data.message.errcode == 0) {
        //解密参数
        let details = AES.decode(data.result);
        console.log(details)
        // self.notice_list = details.data;
        self.details = details;
      }
    });
}
